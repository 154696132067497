<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Dedicated Training Course Content
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Select Training Courses"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                data-testid="training-course-select"
                  v-model="addCourseForm.course"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="coursesList"
                  :reduce="(val) => val"
                  @input="getDuration(addCourseForm.course)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Related Training Courses"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                data-testid="training-course-related"                  v-model="addCourseForm.related_courses"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  multiple
                  :options="RelatedCourse"
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Related Tainers"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Show In"
                rules=""
              >
                <v-select
                data-testid="trainer-course-select"                  v-model="addCourseForm.related_trainers"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="full_name"
                  :options="speakersList"
                  multiple
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          
          <b-col md="4">
            <b-form-group
              label="Related Seminars"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Show In"
                rules=""
              >
                <v-select
                data-testid="seminars-course-select"
                  v-model="addCourseForm.related_seminars"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="eventsList"
                  multiple
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Description"
              label-for="blog-Description"
              class="mb-2"
            >

              <validation-provider
                #default="{ errors }"
                name="Description"
              >
                <quill-editor
                  ref="descriptionEditor"
                   v-model="addCourseForm.description"
                  :options="getEditorOptions('descriptionEditor')"
                />
               
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Objectives"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Objectives"
              >
               <quill-editor
                  ref="objectivesEditor"
                   v-model="addCourseForm.objectives"
                  :options="getEditorOptions('objectivesEditor')"
                />
              
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Who should attend?"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Objectives"
              >
                 <quill-editor
                  ref="attendersEditor"
                   v-model="addCourseForm.attenders"
                  :options="getEditorOptions('attendersEditor')"
                />
              
                      <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Location"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Location"
              >
               <quill-editor
                  ref="locationEditor"
                   v-model="addCourseForm.location"
                  :options="getEditorOptions('locationEditor')"
                />
                       <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Select Day"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="day"
                rules=""
              >
                <v-select
                  id="blog-s-category"
                  v-model="addCourseForm.day"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="durationList"
                  @input="getDayContent(addCourseForm.day)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Day Content"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Objectives"
              >
                 <quill-editor
                  ref="dayContentFormEditor"
                   v-model="dayContentForm"
                  :options="getEditorOptions('dayContentFormEditor')"
                />
              
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
            data-testid="course-submit-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
      <b-modal id="image-properties-modal" title="Image Properties">
      <b-form>
        <b-form-group label="Alt Text">
          <b-form-input v-model="imageProperties.alt"></b-form-input>
        </b-form-group>
        <b-form-group label="Width">
          <b-form-input v-model="imageProperties.width" placeholder="e.g., 100px or 50%"></b-form-input>
        </b-form-group>
        <b-form-group label="Height">
          <b-form-input v-model="imageProperties.height" placeholder="e.g., 100px or auto"></b-form-input>
        </b-form-group>
        <b-form-group label="Alignment">
          <b-form-select v-model="imageProperties.alignment">
            <option value="">None</option>
            <option value="ql-align-left">Left</option>
            <option value="ql-align-center">Center</option>
            <option value="ql-align-right">Right</option>
          </b-form-select>
        </b-form-group>
        <b-button variant="primary" @click="insertImage">Insert</b-button>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,

  BForm,
  BRow,
  BCol,
  BFormGroup,

  BButton,

} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive, watch,getCurrentInstance } from '@vue/composition-api'
import Vue from 'vue'
import Editor from '@tinymce/tinymce-vue'
import store from '@/store'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.snow.css'
// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const RelatedCourse = ref([])
    const Tabimage = ref('')
    const coursesList = ref([])
    const speakersList = ref([])
    const durationList = ref([])
    const eventsList = ref([])
    store.dispatch('speakers/Allspeakers').then(response => {
      speakersList.value = response.data
    })

    store.dispatch('trainingcourses/Alltrainingcourses').then(response => {
      coursesList.value = response.data.data
      RelatedCourse.value = response.data.data
    })
    store.dispatch('events/Allevent').then(response => {
      eventsList.value = response.data
    })
    const addCourseForm = reactive({
      day: 1,
      course: '',
      description: '',
      objectives: '',
      attenders: '',
      days_content: '',
      location:'',
      related_courses: [],
      related_trainers: [],
      related_seminars: [],
    })
    const dayContent = ref([])
    const getDuration = item => {
      console.log(item)
      durationList.value = []
      addCourseForm.day = 1
      coursesList.value.forEach(el => {
        if (el.id == item.id) {
          for (let i = 1; i <= el.duration; i++) {
            durationList.value.push(i)
          }
        }
      })

      addCourseForm.description = item.description

      addCourseForm.related_courses = JSON.parse(item.related_courses)
      addCourseForm.related_trainers = JSON.parse(item.related_trainers)
      addCourseForm.related_seminars = JSON.parse(item.related_seminars)
      addCourseForm.attenders = item.attenders
      addCourseForm.location = item.location
      addCourseForm.objectives = item.objectives
      dayContent.value = JSON.parse(item.days_content)
      dayContentForm.value = dayContent.value[0]

      // addCourseForm.days_content = dayContent.value[0];

      daycontentArray.value = JSON.parse(item.days_content)
    }
   
   
    const daycontentArray = ref([])
    const dayContentForm = ref('')
    watch(dayContentForm, (val, oldVal) => {
      if (!dayContentForm.value) {
        dayContentForm.value = ''
      }

      if (dayContentForm.value !== '') {
        daycontentArray.value[addCourseForm.day - 1] = dayContentForm.value
      } else if (daycontentArray.value[addCourseForm.day - 1]) {
        dayContentForm.value = daycontentArray.value[addCourseForm.day - 1]
        // daycontentArray.value[addCourseForm.day-1]= daycontentArray.value[addCourseForm.day-1]
      }
    })
    const getDayContent = num => {
      if (dayContent.value) {
        if (dayContent.value[num - 1] !== null) {
          dayContentForm.value = daycontentArray.value[num - 1]
        } else {
          dayContentForm.value = ''
        }
      } else {
        dayContentForm.value = ''
      }
    }

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addCourseFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('training_course_id', addCourseForm.course.id)
          formData.append('description', addCourseForm.description)
          formData.append('objectives', addCourseForm.objectives)
          formData.append('attenders', addCourseForm.attenders)
          formData.append('location', addCourseForm.location)
          formData.append(
            'related_courses',
            JSON.stringify(addCourseForm.related_courses),
          )
          formData.append(
            'related_trainers',
            JSON.stringify(addCourseForm.related_trainers),
          )
          formData.append(
            'related_seminars',
            JSON.stringify(addCourseForm.related_seminars),
          )
          formData.append(' days_content', JSON.stringify(daycontentArray.value))
          store
            .dispatch('trainingcourses/addDeticatedContent', formData)
            .then(response => {
              Vue.swal({
                title: 'Content Updated ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    function example_image_upload_handler(blobInfo, success, failure, progress) {
      let xhr; let
        formData
      const token = localStorage.getItem('token')

      xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)

      xhr.upload.onprogress = function (e) {
        progress(e.loaded / e.total * 100)
      }
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      xhr.onload = function () {
        let json

        if (xhr.status === 403) {
          failure(`HTTP Error: ${xhr.status}`, { remove: true })
          return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure(`HTTP Error: ${xhr.status}`)
          return
        }

        json = JSON.parse(xhr.responseText)

        if (!json || typeof json.location !== 'string') {
          failure(`Invalid JSON: ${xhr.responseText}`)
          return
        }

        success(json.location)
      }

      xhr.onerror = function () {
        failure(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
      }

      formData = new FormData()
      formData.append('image', blobInfo.blob(), blobInfo.filename())

      xhr.send(formData)
    }
      const { proxy } = getCurrentInstance()
    const imageProperties = reactive({
      src: '',
      alt: '',
      width: '',
      height: '',
      alignment: '',
    })
    let editorSelection = null
    let currentEditor = null

    const imageHandler = editorRef => {
      currentEditor = proxy.$refs[editorRef].quill
      editorSelection = currentEditor.getSelection()
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()
        formData.append('image', file)

        try {
          const token = localStorage.getItem('token')
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)

          xhr.onload = function () {
            if (xhr.status === 403) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            const json = JSON.parse(xhr.responseText)

            if (!json || typeof json.location !== 'string') {
              console.error(`Invalid JSON: ${xhr.responseText}`)
              return
            }

            imageProperties.src = json.location
            proxy.$bvModal.show('image-properties-modal')
          }

          xhr.onerror = function () {
            console.error(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
          }

          xhr.send(formData)
        } catch (error) {
          console.error('Image upload failed:', error)
        }
      }
    }

    const insertImage = () => {
      const editor = currentEditor
    console.log(currentEditor.getSelection())
      const range = editorSelection
      if (!range) {
        console.error('No selection found')
        return
      }
      const { src, alt, width, height, alignment } = imageProperties
  
      editor.insertEmbed(range.index, 'image', { src, alt, width, height, class: alignment })
      proxy.$bvModal.hide('image-modal-post')
         imageProperties.src = '';
      imageProperties.alt = '';
      imageProperties.width = '';
      imageProperties.height = '';
      imageProperties.alignment = '';
    }


  const linkHandler = editorRef => {
  const range = proxy.$refs[editorRef].quill.getSelection()
  if (range) {
    const url = prompt('Enter the URL')
    if (url) {
      const openInNewTab = confirm('Open in new tab?')
      const selectedText = proxy.$refs[editorRef].quill.getText(range)
      const targetAttribute = openInNewTab ? ' target="oooooooooo" rel="noopener noreferrer"' : ''
      const linkValue = `<a href="${url}"${targetAttribute}>${selectedText}</a>`

      // Remove the selected text
      proxy.$refs[editorRef].quill.deleteText(range.index, range.length)

      // Insert the link at the original selection index
      proxy.$refs[editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue)
    }
  }
}

    const getEditorOptions = editorRef => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            [{ size: SizeStyle.whitelist }],
    ['bold', 'italic', 'underline', 'strike'],
  
    ['clean'],
    ['undo', 'redo'],
    ['paragraph']
          ],
          handlers: {
            image: () => imageHandler(editorRef),
            link: () => linkHandler(editorRef)
          },
        },
      },
    })

    return {
      example_image_upload_handler,
       linkHandler,
       imageHandler,
      insertImage,
      imageProperties,
      getEditorOptions,
      daycontentArray,
      eventsList,
      speakersList,
      dayContentForm,
      refInputEl,
      refPreviewEl,
      RelatedCourse,
      refInputE2,
      refPreviewE2,
      dayContent,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      coursesList,
      getDayContent,
      getDuration,
      durationList,

      save,
      required,
      email,
    }
  },

  components: {
    BCard,
quillEditor,
    ValidationProvider,
    ValidationObserver,

    BForm,

    BRow,
    BCol,
    BButton,
    BFormGroup,

    vSelect,

    Editor,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
